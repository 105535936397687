import { InputConfig } from './inputEditorsConfigs.ts';

export default class InputEditorsItem {
    /** Props from `o365.vue.components.InputEditors.Item.vue` component  */
    private _props: {
        item: any,
        valueSetter?: (pEditorItem: InputEditorsItem, pItem: any, pNewValue: any) => void,
        valueGetter?: (pEditorItem: InputEditorsItem, pItem: any) => any,
    }
    /** DataItem of this input editor */
    private get _item() { return this._props.item; }
    get item() { return this._props.item; } 

    private _displayValue: any;
    displayMember: string | null = null;
    get valueType() { return this._item.DataType ?? ''; }

    editorType: ItemEditorModel['DataType'] = '';
    get column() { return this._item.PropertyName; }

    config?: InputConfig;
    lookupValues?: Array<any>;

    get placeHolder() {
        return this._item.Placeholder;
    }
    get required() {
        return this._item.Required;
    }
    get groupByHeader() {
        return this._item.GroupByHeader;
    }
    get caption() {
        return this._item.Caption;
    }
    get title() {
        return this._item.Title;
    }
    get rows() {
        return this._item.Rows;
    }
    get readonly() {
        const isReadOnly = this._item.Readonly ?? this._item.ReadOnly 
        return isReadOnly == undefined ? false : isReadOnly;
    }
    get displayValue() {
        return this._displayValue ?? this.value;
    }
    set displayValue(pValue: any) {
        this._displayValue = pValue;
    }

    get isInputEditorItem() { return true; }

    get multiple() {
        return !!this.config?.config?.Multiselect
    }

    /** Used in OSelect editor. WHen editor allows multiselect this parses values to/from arrays */
    get selectValue() {
        return this.value?.split(',');
    }
    set selectValue(pValue: any) {
        if (Array.isArray(pValue)) {
            pValue = pValue.filter(x => x).join(',');
        }
        this.value = pValue;
    }

    get value() {
        if (this._props.valueGetter) {
            return this._props.valueGetter(this, this._item);;
        } else if (this.valueType == 'bool' && this._item.IntValue !== null) {
            return this._item.IntValue === 1 ? true : false;
        } else {
            return this._item.Value;
        }
    }
    
    set value(pValue: any) {
        if (this._props.valueSetter) {
            this._props.valueSetter(this, this._item, pValue);
        } else {
            this._item.Value = pValue;
        }
    }

    get isLookup() {
        return this.config?.type === 'Lookup' || this.config?.type === 'OrgUnit' || this.config?.type === 'Object';
    }

    get editor() {
        if (this.lookupValues) {
            return 'multiselect';
        } else if (this.valueType === 'bool') {
            return 'bit';
        } else if (this.valueType === 'date') {
            return 'datetime';
        } else if (this.valueType === 'datetime') {
            return 'datetime2';
        } else if (this.config?.type === 'DatePicker') {
            return 'ddatetime'
        } else if (this.config?.type === 'Lookup') {
            return 'lookup';
        } else if (this.config?.type === 'OrgUnit') {
            return 'orgunit_lookup';
        } else if (this.config?.type === 'Object') {
            return 'object_lookup';
        } else {
            return 'textbox';
        }
    }

    constructor(pProps: {
        item: any,
        valueSetter?: (pEditorItem: InputEditorsItem, pItem: any, pNewValue: any) => void,
        valueGetter?: (pEditorItem: InputEditorsItem, pItem: any) => any,
    }) {
        this._props = pProps;

        if (this._item.Config) {
            this.config = new InputConfig(this._item);
        }
        try {
            this.lookupValues = this._item.LookupValues ? JSON.parse(this._item.LookupValues).map((x: any) => x.Value) : null;
        } catch {
            this.lookupValues = this._item.LookupValues ? this._item.LookupValues.split("|") : null;
        }
    }

}

type ItemEditorModel = {
    PropertyName: string,
    Value?: any,
    IntValue?: number | null,
    DataType?: 'bool' | 'date' | 'datetime' | 'number' | 'string' | '',
    Config?: any,
    LookupValues?: any,
    Required?: any,
    GroupByHeader?: any,
    Caption?: string,
    Placeholder?: string
    Title?: string,
    Rows?: number
};
